import React from 'react'
import styled from 'styled-components'
import BackUs from '../../images/svg/back-us.svg'

const Us = ({ title, usData }) => {
  return (
    <Styled>
      <BackUs className='backImage' />
      <div className='us'>
        <h2 className='title'>{title}</h2>
        <div className='content' dangerouslySetInnerHTML={{ __html: usData }}></div>
      </div>
    </Styled>
  )
}

export default Us

const Styled = styled.section`
  position: relative;
  padding: 1rem 2rem 4rem 1rem;
  overflow-x: hidden;
  .backImage{
    position: absolute;
    top: -57px;
    height: auto;
    z-index: -1;
    width: 200px;
    right: -18%;
    transition: all linear .5s;
    @media screen and (min-width: 600px){
      width: 295px;
      top: -5%;
      right: -10%;
    }
    @media screen and (min-width: 1100px){
      top: -95px;
      width: 450px;
      right: 0;
    }
  }
  .title{
    margin-bottom: 1rem;
  }
  .content{
    max-width:680px;
    color: ${props => props.theme.colors.text.secondary};
    p{
      font-size: 0.875rem;
      line-height: 2em;
      margin-bottom: 1rem;
    }
    p:nth-last-child(1){
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 600px){
    padding: 3.75rem;
    .title{
      margin-bottom: 2rem;
    }
    .content{
      p{
        font-size: 1.125rem;
      }
    }
  }
`