import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

const Hero = () => {
  const data = useStaticQuery(graphql` 
    query{
      file(name: {eq: "us"}) {
        childImageSharp {
          fluid (maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section>
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Quienes somos" />
    </section>
  )
}

export default Hero
