import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/quienes-somos/Hero"
import Us from "../components/quienes-somos/Us"
import Proposal from "../components/quienes-somos/Proposal"
//data
import pageData from "../data/pageData"

const QuienesSomos = ({ location }) => (
  <Layout location={location}>
    <SEO title="Quienes somos" />
    <Hero />
    <Us title={pageData.us.title} usData={pageData.us.about} />
    <Proposal proposalData={pageData.us.proposal} />
  </Layout>
)

export default QuienesSomos