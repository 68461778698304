import React from 'react'
import styled from 'styled-components'
import Carousel from 'react-elastic-carousel'
// icon component
import * as FontIcon from "react-icons/md"

const Icon = props => {
  const { iconName } = props
  const icon = React.createElement(FontIcon[iconName])
  return <CustomIcon>{icon}</CustomIcon>
}
const CustomIcon = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: ${props => props.theme.colors.text.light};
  font-size: 1.5rem;
  background-color: ${props => props.theme.colors.icon.footer};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -50px 0 .7rem;

  @media screen and (min-width: 600px){
    font-size: 2rem;
    width: 60px;
    height: 60px;
    margin: 0 0 1.25rem;
    background-color: ${props => props.theme.colors.icon.primary};
  }
`
const Proposal = ({ proposalData }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 600, enableSwipe: false, enableMouseSwipe: false, pagination: false },
  ]
  return (
    <Style>
      <h2 className='title'>{proposalData.title}</h2>
      <Container
        itemsToShow={1}
        breakPoints={breakPoints}
        showArrows={false}
        itemPadding={[10, 4, 10, 4]}
        style={{ alignItems: 'center' }}>
        {proposalData.items.map((item, i) =>
          <div key={i} className='slider'>
            <Icon iconName={item.icon} />
            <h3>{item.title}</h3>
            <p>{item.excerp}</p>
          </div>
        )}
      </Container>
    </Style>
  )
}

export default Proposal

const Style = styled.section`
  padding: 3rem 0 2.3rem;
  position: relative;
  &:before{
    content:'';
    z-index: -1;
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: #7DB54A;
    background: linear-gradient(315deg, #7DB54A 0%, #00ABC2 100%);
    clip-path: polygon(0% 0%, 90% 35px, 100% 100%, 0% 100%);
  }
  @media screen and (min-width: 600px){
    padding: 5rem 3.75rem 3.75rem;
    &:before{
      clip-path: polygon(0% 0%, 85% 60px, 100% 100%, 0% 100%);
    }
  }
  .title{
    color: ${props => props.theme.colors.text.light};
    margin-bottom: 1.75rem;
    text-align: center;
    @media screen and (min-width: 600px){
      margin-bottom: 3.75rem;
    }
  }
`
const Container = styled(Carousel)`
  overflow-x:hidden;
  .rec-slider-container{
    overflow: initial;
    padding: 0 40px;
    margin-top: 10px;
  }
  .rec-slider{
    min-height: initial;
    padding: 0 40px;
  }
  .rec-item-wrapper{
    overflow: initial;
    height: 100%;
    .slider{
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      text-align: center;
      background-color: #fff;
      padding: 1.875rem;
      color: ${props => props.theme.colors.text.secondary};
      display: flex;
      flex-direction: column;
      align-items: center;
      h3{
        font-size: 1.125rem;
        line-height: 1em;
        color: ${props => props.theme.colors.icon.primary};
        margin-btom:1rem;
      }
      p{
        margin-bottom: 0;
        font-size: .875rem;
        line-height: 2rem;
      }
    }
  }
  @media screen and (min-width: 600px){
    .rec-slider-container{
      overflow: hidden;
      padding: initial;
    }
    .rec-carousel{
    }
    .rec-slider{
      padding: initial;
      flex-wrap: wrap;
      min-height: initial;
      left: initial!important;
      > div{
        width: 50%;
      }
    }
    .rec-item-wrapper{
      overflow: hidden;
      width:100%!important;
      height:100%;
      padding: 10px!important;
      .slider{
        width: 100%;
        h3{
          font-size: 1.5rem;
        }
        p{
          font-size: 1.125rem;
        }
      }
    }
    .rec-pagination{
      display: none;
    }
  }
  .rec-dot{
    border: 0;
    background-color: rgba(255,255,255,0.52);
    width: 8px;
    height: 8px;
    margin: 4px;
    box-shadow: none;
    &.rec-dot_active{
      background-color: #fff;
    }
  }
`